$primary: #74b816;
$secondary: #206bc4;

@import '~@tabler/core';

$ti-font-path: '../../../node_modules/@tabler/icons/iconfont/fonts';

@import '../../../node_modules/@tabler/icons/iconfont/tabler-icons';
@import '~flatpickr';
@import 'actiontext';
@import 'video.js/dist/video-js';
@import 'tom-select/dist/scss/tom-select.bootstrap5';

html,
:root {
  scroll-behavior: auto;
}

body {
  overflow: initial;
}

.sign-in__logo {
  max-width: 200px;
}

.text-left {
  text-align: left;
}

.lcalendar {
  position: relative;

  &__wrap {
    position: absolute;
    left: 50px;
    right: 0;
    top: 0;
    overflow: auto;
    display: flex;
    flex-direction: row;
    height: 100%;
  }

  &__col {
    min-width: 200px;
    flex-grow: 1;
    flex-shrink: 1;
    flex-basis: 0;
    position: relative;
  }

  &__timelines {
    width: 100%;
    padding-top: 30px;
  }

  &__timeline {
    height: 100px;
    border-top: 1px solid $border-color;
  }

  &__legend {
    text-align: center;
    height: 30px;
  }

  &__item {
    position: absolute;
    left: 0;
    right: 0;
    border: 1px solid $gray-600;
    padding: 0 15px;
    display: flex;
    flex-direction: row;
    align-items: center;

    >div {
      position: relative;
    }

    &--muted {
      background: #e9eaeb;
    }

    &--pending {
      opacity: 0.5;
    }

    &--teacher_availability {
      &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        opacity: 0.5;
        background:
          repeating-linear-gradient(
            45deg,
            transparent,
            transparent 10px,
            #fff 10px,
            #fff 20px
          );
      }
    }

    &--test_lesson {
      border: 3px dashed $primary;
      padding: 0 13px;
    }
  }

  a {
    color: inherit;
    text-decoration: none;
  }
}

.form-borderless {
  border: 0;
  box-shadow: none !important;
}

.form-filter-border {
  @include media-breakpoint-up(lg) {
    border-right: 1px solid #dee2e6;
  }
}

.form-help {
  vertical-align: 1px;
  border: 1px solid #dee2e6;
}

.accordion-body {
  background: #fff;
}

.video-cont {
  position: relative;
  padding-top: 56.25%;

  video-js {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border: 0;
  }
}

.text-overflow {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.ti-huge {
  font-size: 2rem;
}

.w-200px {
  width: 200px;
}

.fit-content {
  width: fit-content;
}

.chat {
  min-height: 0;
  padding: 1.5rem;
}

#chat-container {
  height: calc(100vh - 56px);
}

.remove_paddings {
  margin: -1.25rem -1.5rem;
}

.max-90 {
  max-width: 90%;
}

.textarea-auto-resize {
  resize: none;
  height: 36px;
}

.aspect-16-9 {
  aspect-ratio: 16 / 9;
}

.badge--nav {
  top: 50% !important;
  right: 1.5rem !important;
}

.filters {
  @include media-breakpoint-down(md) {
    flex-direction: column;
    flex-grow: 1;

    & > *:not(.offcanvas) {
      width: 100%;
      margin-bottom: 1rem;
      margin-right: 0 !important;
      margin-left: 0 !important;
    }
  }
}

.client-tab-list {
  .card-title {
    line-height: 36px;
  }
}

.zoom-overlay {
  position: relative;
  width: fit-content;

  &::before {
    content: $ti-icon-zoom-in;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    @extend .ti;

    font-size: 2rem;
    display: none;
  }

  &:hover::before {
    display: block;
  }

  @media (max-width: 768px) {
    &::before {
      display: block;
    }
  }
}
